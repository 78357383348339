<script lang="ts" setup>
import { OutletProps } from "~/components/ProductBlocks/Outlet.props"
import { smOptions, mdOptions } from "./Outlet.carouselOptions"
import { VerticalProps } from "~/components/ProductTile/Overall/Vertical.props"
import { sm } from "~/utils/breakpoints"

const props = defineProps<OutletProps>()

const currentOptions = computed(() => (sm.value ? smOptions : mdOptions))

const chunkedProductList = chunk(props.products as VerticalProps[], 12)
</script>

<template>
  <div class="padded carousel-container-overflowed bg-white py-9">
    <GAListCarouselOutletProduct :name="title">
      <header class="pb-6 md:pb-8">
        <p v-if="title" class="elephant-bold md:mammoth-bold">{{ title }}</p>
        <div class="mt-4 flex justify-between">
          <UtilsMarkdown
            v-if="description"
            class="pig text-black-80"
            :content="description"
          />
          <UtilsButton
            v-if="cta"
            v-bind="cta"
            class="hidden md:block"
            theme="outlined-green"
          />
        </div>
      </header>

      <GAItem
        :id="`all-out-products--${j}`"
        :item="(products as VerticalProps[])"
        v-for="(products, j) in chunkedProductList"
        :key="j"
      >
        <GAViewItemList> </GAViewItemList>
      </GAItem>
      <AppCarousel
        v-if="products?.length"
        class="carousel-overflowed"
        :class="{ 'mb-4 md:mb-0': cta }"
        :swiper-props="currentOptions"
        :items="products"
        :theme="currentOptions.theme"
        :key="sm ? 1 : 0"
      >
        <template #default="{ item }">
          <ProductTileOverallVertical
            class="swiper-tile"
            :="item"
            :itemList="`Outlet: ${title}`"
          />
        </template>
      </AppCarousel>

      <UtilsButton
        v-if="cta"
        v-bind="cta"
        class="pt-4 md:hidden"
        theme="outlined-green"
        fluid
      />
    </GAListCarouselOutletProduct>
  </div>
</template>

<style lang="scss" scoped>

:deep(.swiper-slide:first-child > div) {
  margin-left: 0;
}

:deep(.swiper-slide:last-child > div) {
  margin-right: 0;
}
.swiper-tile {
  margin: 0 8px;
}

@screen md {
  .swiper-tile {
    margin: 0 12px;
    width: calc(((100vw - 142px) / 4) - 24px);
  }
}

@screen lg {
  .swiper-tile {
    width: calc(((100vw - 456px) / 4) - 24px);
  }
}

</style>
