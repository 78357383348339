import { CarouselProps } from "~/utils/carouselProps"
import { Pagination, Navigation } from "swiper/modules"

export const smOptions: CarouselProps = {
  slidesPerView: "auto",
  grabCursor: true,
  navigation: true,
  pagination: {
    enabled: true
  },
  modules: [Pagination],
  theme: "pagination-tiffany-tiffany30"
}

export const mdOptions: CarouselProps = {
  slidesPerView: "auto",
  slidesPerGroup: 4,
  grabCursor: true,
  navigation: true,
  modules: [Navigation],
  theme: "navigation-dark-middle"
}
